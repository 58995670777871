<template lang="pug">
v-card(v-if="Object.keys(documentSailor).length" header-tag="header")
  v-card-title.d-flex.justify-content-between
    div.d-flex
      div.text-uppercase.text-left {{ $t(`${Object.keys(documentSailor.behavior)[0]}-backOfficeCoursePrice`, { course: documentSailor.course[labelName] }) }}
      span(v-if="checkAccess('backOffice')") (ID: {{ documentSailor.id }})
    div.documentSailorIconControl
      ControlButtons(:controlButtons="controlButtons")
  v-card-text
    BackOfficeCoursePriceInfo(
      v-if="documentSailor.behavior.viewInfoBlock"
      :documentSailor="documentSailor")
    BackOfficeCoursePriceEdit(
      v-else-if="documentSailor.behavior.viewEditBlock"
      :documentSailor="documentSailor")
</template>

<script >
import { viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      checkAccess,
      viewDetailedComponent,
      controlButtons: [
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.documentSailor, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: {
            xSmall: false,
            fab: true,
            icon: true,
            color: '#42627e',
            outlined: false
          }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.documentSailor, 'viewEditBlock'),
          checkAccess: () => this.documentSailor.allowEdit && checkAccess('backOfficeCoursePrice', 'edit'),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: {
            xSmall: false,
            fab: true,
            icon: true,
            color: '#42627e',
            outlined: false
          }
        },
        {
          id: 6,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteCoursePrice(),
          checkAccess: () => this.documentSailor.allowDelete && checkAccess('backOfficeCoursePrice', 'delete'),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete',
          buttonOptions: {
            xSmall: false,
            fab: true,
            icon: true,
            color: '#F73E07',
            outlined: false
          }
        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => this.goBack(),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: {
            xSmall: false,
            fab: true,
            icon: true,
            color: '#42627e',
            outlined: false
          }
        }
      ]
    }
  },
  components: {
    BackOfficeCoursePriceInfo: () => import('./BackOfficeCoursePricesOnlineInfo.vue'),
    BackOfficeCoursePriceEdit: () => import('./BackOfficeCoursePricesOnlineEdit.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      documentSailor: state => state.backoffice.coursePrice
    }),
    documentID () {
      return this.$route.params.id
    }
  },
  methods: {
    ...mapActions(['getCoursePriceOnline', 'deleteCoursePriceOnline']),
    goBack () {
      this.$router.push({ name: 'backoffice-online-price-course' })
    },
    async getDocument () {
      const { id } = this.$route.params
      await this.getCoursePriceOnline({ id })
    },
    deleteCoursePrice () {
      const { id } = this.$route.params

      this.$swal({
        title: 'Do you want delete a price the course ?',
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async (confirm) => {
        if (confirm) {
          await this.deleteCoursePriceOnline({ id })
          this.$notification.success('coursePriceDeleted')
          this.$router.go(-1)
        }
      })
    }
  },
  mounted () {
    this.getDocument()
  }
}
</script>
